<template>
    <div id="donation-site-page">
        <div class="home-page">
            <v-form  @submit.prevent="updateCard">
                <v-skeleton-loader type="list-item-three-line" v-if="themeLoading" :loading="true" ></v-skeleton-loader>
                <v-row v-if="!themeLoading" align="center" justify="center" class="ma-0 text-center pa-0 text-h5 text-lg-h4 text-capitalize primary--text font-weight-light text-break">{{ themeModel.heading }}</v-row>
                <v-row v-if="!themeLoading" align="center" justify="center" class="ma-0 text-center my-2 text-body-2 text-lg-body-1 pa-0 primary--text text-capitalize font-weight-medium text-break">{{ themeModel.description }}</v-row>
                <v-card-title class="text-capitalize primary--text px-0 font-weight-bold justify-center text-center text-break text-uppercase text-h5" :class="{'mb-5': !cardUpdated && !model.invalidCard}" >
                    {{"Update Card" | textCapts}}
                </v-card-title>
                <template v-if="cardUpdated">
                    <div>
                         <v-row align="center" justify="center">
                            <v-img contain class="ma-8" src="@/assets/icons/success-icon.png" width="90" height="90" />
                        </v-row>
                        <v-card-title class="text-capitalize primary--text px-0 font-weight-bold justify-center text-center text-subtitle-1 text-lg-h6">Your card has been updated successfully!</v-card-title>
                    </div>
                </template>
                <template v-else-if="model.invalidCard">
                    <div>
                         <v-row align="center" justify="center">
                            <v-img contain class="ma-8" src="@/assets/icons/error-icon.png" width="90" height="90" />
                        </v-row>
                        <v-card-title class="text-capitalize primary--text px-0 font-weight-bold justify-center text-center text-subtitle-1 text-lg-h6">Your opportunity id was invalid!</v-card-title>
                    </div>
                </template>
                <template v-else>
                    <PaymentForm :valid="$v" :submitForm="submit" @updateRefs="refsObj={...refsObj, ...$event}" :stripe="stripe">
                        <template slot="receiptName">
                            <Input :label="model.payment_type === 'CC' ? `Card Holder's Name*` : 'Account Name*'" :labelCls="{'error--text': $v.model.receipt_name.$dirty && !$v.model.receipt_name.required}">
                                <template v-slot:input>
                                    <v-text-field name="Receipt Name" solo ref="receipt_name" class="box-shadow-none rounded-lg text-capitalize" color="primary"  v-model="model.receipt_name"
                                        :error-messages="$helpers.errorMsg('name', $v.model.receipt_name, model.payment_type === 'CC' ? `Card Holder's Name` : 'Account Name')"
                                        hide-details="auto" :label="model.payment_type === 'CC' ? 'Name On Card' : 'Account Name'" outlined required
                                    />
                                </template>
                            </Input>
                        </template>
                    </PaymentForm>
                    <v-row class="mt-5" justify= 'center'>
                        <v-col cols="12" class="col-lg-6 d-flex justify-center justify-lg-start align-center mobile-page">
                            <v-btn type="submit" color="secondary" rounded large class="w-full font-weight-bold" ref="submit_button save-card" :loading="intentLoading"
                                >
                                <template v-slot:loader>
                                    <v-progress-circular indeterminate :size="20" color="white"></v-progress-circular>
                                    <span class="ml-2">Loading...</span>
                                </template>
                                Save Card
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

import { Input } from '@/components';

export default {
    components: {
        Input,
        PaymentForm: () => import("@/pages/dashboard/component/PaymentForm")
    },
    name: "Upate Card",
    validations() {
        const valid = {
            model: {
                receipt_name: { required }
            }
        };

        return valid;
    },
    data: (vm) => ({
        submit: false,
        stripe: null,
        refsObj: {},
        themeModel: {
            heading: 'Thanks For Giving To Keep TheLight Shining!',
            description: "Your Amazing Gift Helps Spread Words Of Life, Hope, & Encouragement To Families All Over Melbourne! You Are Making A Difference - Thank You For Supporting Your 89.9 TheLight",
            frequency: 'O',
            once_amount: [{amount: 200, tag:'', default: 0}, {amount: 400, tag:'', default: 1}, {amount: 600, tag:'', default: 0}],
            recurring_amount: [{amount: 200, tag:'', default: 0}, {amount: 400, tag:'', default: 1}, {amount: 600, tag:'', default: 0}]
        },
        cardTokenId: vm.$route.params.tokenId,
        intentLoading: false,
        clientSecret: null,
        cardUpdated: false
    }),
    computed: {
        ...mapGetters(["themeApiModel", "themeLoading"]),

        paymentModel: {
            get() {
                return this.$store.getters["paymentModel"];
            },
            set(newValue) {
                return this.$store.commit("updatePaymentModel", newValue);
            },
        },
        model: {
            get() {
                return this.$store.getters["model"];
            },
            set(newValue) {
                return this.$store.commit("updateModel", newValue);
            },
        },
    },
    watch: {
        themeApiModel: function(newValue) {
            if (newValue) {
                const valueThemeModel = this.$helpers.getValueOnlyObj(newValue);
                this.themeModel = {...this.themeModel, ...valueThemeModel};
            }
        },
        paymentPage(value) {
            if(value && !this.model.receipt_name) {
                this.model.receipt_name = `${this.model.first_name} ${this.model.last_name}`;
            }
        }
    },
    filters: {
        textCapts: function(text) {
            if (text) {
                const caseWords = (text.trim()).split(' ').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ');
                return caseWords.replace('Thelight', 'TheLight');
            }
            return '';
        }
    },
    created() {
        const valueThemeModel = this.$helpers.getValueOnlyObj(this.themeApiModel);
        this.themeModel = {...this.themeModel, ...valueThemeModel};
    },
    mounted() {
        this.stripe = window.Stripe(`${window.VUE_APP_STRIPE_PUBLISHABLE_KEY}`);
    },
    methods: {
        ...mapActions(["updateCardDetails", "createStripeSetupIndent"]),

        updateCard() {
            this.submit = true;
            this.$v.$touch();
            if (this.$v.$invalid || !this.paymentModel.cardValidate || !this.model.amount) {
                this.$helpers.focusErrorElement(this.$v.model, this.refsObj);
                return;
            }
            this.$store.commit("startAppLoading");
            const paymentData = {
                type: this.model.payment_type === 'DD' ? 'au_becs_debit' : 'card',
                billing_details: {
                    name: this.model.receipt_name,
                    email: this.model.email,
                },
            }, reqData = { payment_method_types : ['card'] };
            if ( this.model.payment_type === 'DD') {
                paymentData['au_becs_debit'] = this.paymentModel.auBankAccount;
                reqData['payment_method_types'] = ['au_becs_debit'];
            } else {
                paymentData['card'] = this.paymentModel.cardNumber;
            }
            this.createStripeSetupIndent(reqData)
                .then((result) => {
                    if(result) this.stripeConfirmPayment(paymentData, result.client_secret);
                }).catch(err => {});
        },
        stripeConfirmPayment(paymentData, clientSecret) {
            const confirmMethod = this.model.payment_type === 'DD' ? 'confirmAuBecsDebitSetup' : 'confirmCardSetup';
            this.stripe[confirmMethod](clientSecret, { payment_method: paymentData })
                .then((result) => {
                    if(result.error) {
                        this.paymentModel.stripeErr = result.error.message;
                        this.$store.commit("snackbar/SHOW_MESSAGE", {
                            text: result.error.message || 'Payment Failed',
                            color: "error",
                        });
                    } else {
                        this.$store.commit("startAppLoading");
                        this.paymentModel.stripeErr = '';
                        this.saveCardDetail(result);
                    }
                }).catch(err => {});
        },
        saveCardDetail(result) {
            const reqData = {
                donation_id: this.model.donation_id,
                reqData: {
                    receipt_name: this.model.receipt_name,
                    payment_intent_id: result.setupIntent.id,
                    payment_method_id: result.setupIntent.payment_method
                }
            };
            this.updateCardDetails(reqData).then(() => {
                this.cardUpdated = true;
            }).catch();
        },
    }
};
</script>
